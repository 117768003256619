// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-vi-js": () => import("./../src/pages/about.vi.js" /* webpackChunkName: "component---src-pages-about-vi-js" */),
  "component---src-pages-awards-en-js": () => import("./../src/pages/awards.en.js" /* webpackChunkName: "component---src-pages-awards-en-js" */),
  "component---src-pages-awards-vi-js": () => import("./../src/pages/awards.vi.js" /* webpackChunkName: "component---src-pages-awards-vi-js" */),
  "component---src-pages-betty-tran-brand-en-js": () => import("./../src/pages/betty-tran-brand.en.js" /* webpackChunkName: "component---src-pages-betty-tran-brand-en-js" */),
  "component---src-pages-betty-tran-brand-vi-js": () => import("./../src/pages/betty-tran-brand.vi.js" /* webpackChunkName: "component---src-pages-betty-tran-brand-vi-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-vi-js": () => import("./../src/pages/careers.vi.js" /* webpackChunkName: "component---src-pages-careers-vi-js" */),
  "component---src-pages-community-js": () => import("./../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-community-vi-js": () => import("./../src/pages/community.vi.js" /* webpackChunkName: "component---src-pages-community-vi-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-vi-js": () => import("./../src/pages/contact.vi.js" /* webpackChunkName: "component---src-pages-contact-vi-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-faq-vi-js": () => import("./../src/pages/faq.vi.js" /* webpackChunkName: "component---src-pages-faq-vi-js" */),
  "component---src-pages-focus-js": () => import("./../src/pages/focus.js" /* webpackChunkName: "component---src-pages-focus-js" */),
  "component---src-pages-focus-vi-js": () => import("./../src/pages/focus.vi.js" /* webpackChunkName: "component---src-pages-focus-vi-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-vi-js": () => import("./../src/pages/index.vi.js" /* webpackChunkName: "component---src-pages-index-vi-js" */),
  "component---src-pages-people-js": () => import("./../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-people-vi-js": () => import("./../src/pages/people.vi.js" /* webpackChunkName: "component---src-pages-people-vi-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policy-vi-js": () => import("./../src/pages/privacy-policy.vi.js" /* webpackChunkName: "component---src-pages-privacy-policy-vi-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-vi-js": () => import("./../src/pages/services.vi.js" /* webpackChunkName: "component---src-pages-services-vi-js" */),
  "component---src-pages-sustainability-js": () => import("./../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-sustainability-vi-js": () => import("./../src/pages/sustainability.vi.js" /* webpackChunkName: "component---src-pages-sustainability-vi-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-and-conditions-vi-js": () => import("./../src/pages/terms-and-conditions.vi.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-vi-js" */),
  "component---src-pages-testimonials-js": () => import("./../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-testimonials-vi-js": () => import("./../src/pages/testimonials.vi.js" /* webpackChunkName: "component---src-pages-testimonials-vi-js" */),
  "component---src-pages-vision-and-mission-js": () => import("./../src/pages/vision-and-mission.js" /* webpackChunkName: "component---src-pages-vision-and-mission-js" */),
  "component---src-pages-vision-and-mission-vi-js": () => import("./../src/pages/vision-and-mission.vi.js" /* webpackChunkName: "component---src-pages-vision-and-mission-vi-js" */)
}

